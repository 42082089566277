import React from "react";
import {Provider} from "react-redux";
import * as reactRouterDom from "react-router-dom";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import SuperTokens, {SuperTokensWrapper} from "supertokens-auth-react";
import Passwordless from "supertokens-auth-react/recipe/passwordless";
import Session, {SessionAuth} from "supertokens-auth-react/recipe/session";
import {getSuperTokensRoutesForReactRouterDom} from "supertokens-auth-react/ui";
import {PasswordlessPreBuiltUI} from "supertokens-auth-react/recipe/passwordless/prebuiltui";
import store from "./app/store";
import DayViewAgendaManager from "./features/agenda/day/DayViewAgendaManager";
import WeekViewAgendaManager from "./features/agenda/week/WeekViewAgendaManager";
import LayoutBaselineProvider from "./LayoutBaselineProvider";

const MD_BREAKPOINT = 768;

const hostConfig = window.location.hostname === "localhost" ? {
    apiDomain: "https://localhost:3000",
    websiteDomain: "https://localhost:3000",
    apiBasePath: "/_super-tokens/auth",
} : {
    apiDomain: "https://login.dev.trisystems.online",
    websiteDomain: "https://app.dev.trisystems.online",
    apiBasePath: "/auth",
}

SuperTokens.init({
    appInfo: {
        appName: "TRS",
        websiteBasePath: "/login",
        ...hostConfig
    },
    recipeList: [
        Passwordless.init({
            contactMethod: "EMAIL"
        }),
        Session.init({})
    ]
});

const App = () => {
    const isMdScreen = window.innerWidth >= MD_BREAKPOINT;
    return (
        <SuperTokensWrapper>
            <Provider store={store}>
                <LayoutBaselineProvider>
                    <Router>
                        <Routes>
                            {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [PasswordlessPreBuiltUI])}
                            <Route
                                path="/"
                                element={<Navigate to="/agenda"/>}
                            />
                            <Route
                                path="/agenda"
                                element={<Navigate to={isMdScreen ? "/agenda/week" : "/agenda/day"}/>}
                            />
                            <Route
                                path="/agenda/week"
                                element={<SessionAuth><WeekViewAgendaManager/></SessionAuth>}
                            />
                            <Route
                                path="/agenda/day"
                                element={<SessionAuth><DayViewAgendaManager/></SessionAuth>}
                            />
                        </Routes>
                    </Router>
                </LayoutBaselineProvider>
            </Provider>
        </SuperTokensWrapper>
    )
};

export default App;
