import {fetchBaseQuery} from "@reduxjs/toolkit/query";
import {FetchBaseQueryArgs} from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import Session from 'supertokens-auth-react/recipe/session';

// noinspection TypeScriptUnresolvedVariable
const baseQuery = fetchBaseQuery({
    // baseUrl: process.env.API_BASE_URL,
    baseUrl: `https://api.dev.trisystems.online/`,
    prepareHeaders: async (headers) => {
        headers.set('Accept', 'application/json');
        headers.set('Content-Type', 'application/json');
        // TODO move to state
        const isLoggedIn = await Session.doesSessionExist();
        if (isLoggedIn) {
            const {jwt} = await Session.getAccessTokenPayloadSecurely();
            headers.set('Authorization', `Bearer ${jwt}`)
        }
        return headers
    },
} as FetchBaseQueryArgs);

export default baseQuery